import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from './loader-service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class LoaderInterceptor  implements HttpInterceptor {
  requestCount:number=0

  constructor(private loaderService: LoaderService) {
  }


  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.requestCount++
    this.loaderService.show();

    return next.handle(request).pipe(
          finalize(() =>{
            this.requestCount--
            if(this.requestCount==0)
            this.loaderService.hide()
          }),
    );
 }
}