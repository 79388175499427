<!-- <div *ngIf="isLoading | async" class="overlay">
    <mat-progress-spinner class="spinner"
                          mode="indeterminate">
    </mat-progress-spinner>
  </div> -->
<!-- 

  <div class="loader"  *ngIf="isLoading | async" >
    <svg width="200" height="200" viewBox="0 0 100 100">
      <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" class="loading-text">Loading Data</text>
      <circle cx="50" cy="50" r="45" stroke="#3498db" stroke-width="4" fill="none" class="spinner" />
    </svg>
  </div> -->


  <div  *ngIf="isLoading | async" class="overlay">
    <svg class="loader" viewBox="0 0 50 50">
      <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="4"></circle>
    </svg>
    <p class="loading-text">Loading...</p>
  </div>
